(function($)
{
    var shader = null;

    /**
     * Options for the plugin
     */
    var settings;
    /**
     * all methods of the plugin
     * @type {{init: methods.init, start: methods.start, reset: methods.reset}}
     */
    var methods = {
        /**
         * Init the plugin
         * @param options
         * @returns {*}
         */
        init: function(options)
        {
            settings = $.extend({
                color: '#000',
                opacity : 0.6,
                duration : 200,
                onClick : function () {}
            }, options);

            return this.each(function()
            {
                shader = $('<div/>', {'class': 'shader'})
                    .css({
                        'top': 0 ,
                        'position': 'fixed',
                        'left':0,
                        'right': 0,
                        'bottom':0,
                        'background-color': settings.color,
                        'opacity': settings.opacity,
                        'z-index':1000,
                        'transition': 'opacity 300ms'})
                    .on('click', function (e) {
                        settings.onClick();
                        methods.close($(this));
                    });
                $(this).append(shader);
            });
        },
        open: function (that){
            shader.addClass('in');
        },
        close: function (that) {
            shader.removeClass('in');
        },
        /**
         * start animation after an event
         * @param direction
         **/
         toggle: function(direction)
         {
             var that = $(this);
             if($(that).hasClass('in')){
                 methods.close(that);
             }
             else {
                 methods.open(that);
             }
         }


    };

    /**
     * declare plugin with methods
     * @param methodOrOptions
     * @returns {*}
     */
    $.fn.shader = function(methodOrOptions)
    {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof methodOrOptions === 'object' || !methodOrOptions)
        {
            // Default to "init"
            return methods.init.apply(this, arguments);
        } else
        {
            $.error('Method ' + methodOrOptions + ' does not exist on jQuery.tooltip');
        }
    };
})(jQuery);
