/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        /**
         * Logo stuff on top of homesite
         * @type {jQuery|HTMLElement}
         */
        var logosTop = $('.home-logos');

        /**
         * Sidebars (wraper for filter and menus
         * @type {HTMLElement|jQuery}
         */
        var sidebarRight = $(".aside-filter .nav-affix ");

        sidebarRight.affix({
          offset: {
            top: function () {
              var top = 1;
              if( logosTop.is(':visible')){
                top += logosTop.outerHeight(true);
              }
              return top;
            },
            bottom: 1
          }
        });



        /**
         * Sidebar left (wrapper for filter and menu)
         * @type {HTMLElement|jQuery}
         */

        var sidebarLeft = $(".sidebar .nav-affix ");

        // init affix on the left side
        sidebarLeft.affix({
          offset: {
            top: function () {
              var top = 1;
              if( logosTop.is(':visible')){
                top = logosTop.outerHeight(true) + top;
              }
              return top;
            },
            bottom: 1
          }
        });

        // set width and height of the element
        sidebarLeft.on('affix.bs.affix', function () {
          // the logo get displayed, keep space
          // $(this).css('top', logosTopSmall.outerHeight(true));
          // position is now fixed, set width
          $(this).width($(this).width());

          if($('.placeholder').is(":visible")){
            $(this).css('top', $('.placeholder').height());
          }
          if($(window).width() <= 768){
            $(this).css('top', $('#logo-small').height());
          }

        });

        // reset logo
        sidebarLeft.on('affix-top.bs.affix', function () {
          $(this).css('top', 0);
          if($(window).width() <= 768){
            $(this).css('top', $('#logo-small').height());
          }
        });

        // render menus and filter on resize
        $(window).on('resize', function (e){
          sidebarLeft.width(sidebarLeft.parent().width());
        });


        /**
         * Search box
         * @type {jQuery}
         */
        var searchBox = $('.widget_search');
        // affix to the bottom
        searchBox.affixedBottom({offsetTop:0});
        /**
         * Filter on the right side. Showed at small screens
         * @type {jQuery|HTMLElement}
         */
        var filterRight = $('.filter-wrapper.right');
        filterRight.digikomFilter({direction:'left'});

        /**
         * Filter on the left side. Showed at small screens
         * @type {jQuery|HTMLElement}
         */
        var filterLeft = $('.filter-wrapper.left');
        // just add plugin at a certain width
        $(window).on('resize', function (){
          if($(window).width() <= 768 && (typeof filterLeft.data('settings') === 'undefined')){
            filterLeft.digikomFilter({direction:'right'});
            // replace all images on filter
            filterLeft.find('section img').each(function(index,data){
              var oldImage = $(this).attr('src');
              $(this).data('image', oldImage);
              $(this).attr('src','data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==');
            });
          }
           else if ($(window).width() > 768 && (typeof filterLeft.data('settings') !== 'undefined')){
            filterLeft.digikomFilter('destroy');
            // replace all images on filter
            filterLeft.find('section img').each(function(index,data){
              var oldImage = $(this).data('image');
              $(this).attr('src',oldImage);
            });

          }
        });

        // Search-button
        searchBox.find(".search-submit").on('click', function (e){
          if(($(window).width() <= 768) && (filterLeft.hasClass('open') === false)){
            e.preventDefault();
            filterLeft.digikomFilter('toggle');
          }
          else if($('.search-field').val() === ''){
            e.preventDefault();
            $('.search-field').focus();
          }
          else {
            return true;
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        /**
         * Infinite scrolling
         * Options for infiniteScroll plugin
         * @type {object}
         */
        var infinite_scroll = {
          'nextSelector'      : '.nav-previous a',
          'prevSelector'      : '.nav-next a',
          'navSelector'       : '.posts-navigation',
          'itemSelector'      : '.col-md-4.post',
          'contentSelector'   : '.main',
          'loader'            : '<img src="/wp-content/themes/kingcom/dist/images/loader.gif" width="50px" height="50px">',
          'is_shop'           : false
        };
        $( infinite_scroll.contentSelector ).yit_infinitescroll( infinite_scroll );


        // make posts clickable delegate from html urrg
        $('html').on('click', '.home .post, .archive .post, .category .post, .tax-research_category .cp_research, .research-cats .research-cats > .row', function (e){
          if($(e.target).is('a')) {
            return true;
          }
          if($(this).find('.entry-title  a').length > 0){
            window.location.href = $(this).find('.entry-title  a').attr('href');
          }
          else if ($(this).find('h3  a').length > 0) {
            window.location.href = $(this).find('h3  a').attr('href');
          }
          else {
            return true;
          }
        });


        // initial triggers
        $(window).trigger('resize');
        $(window).trigger('scroll');

        // cut too long text on home and categories and archive
        $( window ).load(function() {
          var postElements = $('.home article.type-post .entry-summary, .category article.type-post .entry-summary, .archive article.type-post .entry-summary');
          postElements.ellipsis({live:true});
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
