(function($)
{

    var currentPushState;
    var loading = false;
    /**
     * Options for the plugin
     */
    var settings;





    var methods = {
        /**
         * Init the plugin
         * @param options
         * @returns {*}
         */
        init: function(options)
        {
            settings = $.extend({
                selector: '.main',
                traget : '.main',
                replace: true
            }, options);

            /**
             * Listen to popState event (gets fired when clicking
             * forward or back button in browser)
             */
            window.onpopstate = function(event)
            {
                console.log("pop" + location.pathname);
                loadPage(location.pathname);
            };


            return this.each(function()
            {
                var that = $(this);
                that.on("click", function (e) {
                    e.preventDefault();
                    if($(e.target).is('img') || loading){
                        return;
                    }

                    // register changes in history
                    var callback = function (data){
                        window.history.pushState({data:  Math.random()}, data.title, data.url);
                    };
                    methods.loadPage(that.attr("href"), callback);
                });
            });
        },
        loadPage: function (url, callback) {
            var replace;
            var promise = $.ajax({
                url: url,
                method: "GET",
                dataType: "html",
                success: function (data){
                    // console.log("ok");
                    var content = $(data).find(settings.selector);
                    var title = $(data).find('title').text();
                    // wait until fadeout is done
                    replace.promise().done(function(){
                        if(settings.replace) {
                            replace.replaceWith(content).hide().fadeIn();
                            // todo: decouple
                            window.setTimeout(function () {
                                $(document).trigger('yith-wcan-ajax-filtered');
                            },100);
                        }
                        else {
                            replace.append(content);
                        }
                    });
                    // add active link
                    $('a[href$="'+url+'"]:first').addClass('active-ajax');

                    // callback f.e to push state to history
                    if(typeof (callback) === 'function') {
                        callback({title: title, url: url, data: data});
                    }
                },
                beforeSend: function (){
                    loading = true;
                    // remove active link
                    $('.active-ajax').removeClass('active-ajax');

                    // redefine the replace container
                    replace = $(settings.traget);
                    replace.fadeOut();
                },
                error: function () {
                    if(settings.replace) {
                        replace.replaceWith($("<p>There was an error. Please try again</p>"));
                    }
                    else {
                        replace.append($("<p>There was an error</p>"));
                    }
                },
                complete: function () {
                    loading = false;
                }
            });
            return promise;
        }
    };

    /**
     * declare plugin with methods
     * @param methodOrOptions
     * @returns {*}
     */
    $.fn.ajaxmenu = function(methodOrOptions)
    {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof methodOrOptions === 'object' || !methodOrOptions)
        {
            // Default to "init"
            return methods.init.apply(this, arguments);
        } else
        {
            $.error('Method ' + methodOrOptions + ' does not exist on jQuery.tooltip');
        }
    };
})(jQuery);
