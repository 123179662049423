(function ($, window, document) {
    "use strict";

    $.fn.yit_infinitescroll = function (options) {

        // reset
        $( window ).unbind( 'yith_infs_start' );

        var opts = $.extend({
                nextSelector   : false,
                prevSelector   : false,
                offset   : {top:0,bottom:0},
                navSelector    : false,
                itemSelector   : false,
                contentSelector: false,
                maxPage        : false,
                loader         : false,
                is_shop        : false

            }, options),

            loading  = false,
            lastSite = false,
            firstSite = false,
            nextUrl = false,
            prevUrl = false;

        // hide std navigation
        if($( opts.navSelector ).length){
            $( opts.navSelector ).hide();
            //console.log("hide navigation");
        }

        // validate options
        if(!lastSite && $( opts.nextSelector ).length && $( opts.navSelector ).length && $( opts.itemSelector ).length && $( opts.contentSelector ).length ) {
            nextUrl = $( opts.nextSelector ).attr( 'href' ); // init next url
        }
        else {
            // set finished true
            lastSite = true;
        }

        // validate options and hide std navigation
        if( $( opts.prevSelector ).length && !firstSite) {
            prevUrl = $( opts.prevSelector ).attr( 'href' ); // init next url
        }
        else {
            firstSite = true;
        }

        // set elem columns ( in shop page )
        var first_elem  = $( opts.contentSelector ).find( opts.itemSelector ).first(),
            columns = first_elem.nextUntil( '.first', opts.itemSelector ).length + 1;

        // recalculate element position
        var position_elem = function( last, columns, elem ) {


            var offset  = ( columns - last.prevUntil( '.last', opts.itemSelector ).length ),
                loop    = 0;

            elem.each(function () {

                var t = $(this);
                loop++;

                t.removeClass('first');
                t.removeClass('last');

                if ( ( ( loop - offset ) % columns ) === 0 ) {
                    t.addClass('first');
                }
                else if ( ( ( loop - ( offset - 1 ) ) % columns ) === 0 ) {
                    t.addClass('last');
                }
            });
        };


        var main_ajax = function (direction) {

            var last_elem   = $( opts.contentSelector ).find( opts.itemSelector ).last();
            var url;

            if(typeof direction === 'undefined' || direction.length === 0) {
                direction = 'next';
            }

            // set loader and loading
            if( opts.loader ) {$(opts.navSelector).after('<div class="yith-infs-loader">' + opts.loader + '</div>');}
            loading = true;

            if(direction === 'next'){
                url = nextUrl;
            }
            else {
                url = prevUrl;
            }

            // ajax call
            $.ajax({
                // params
                url         : url,
                dataType    : 'html',
                success     : function (data) {

                    var obj  = $( data),
                        elem = obj.find( opts.itemSelector ),
                        next = obj.find( opts.nextSelector),
                        prev = obj.find( opts.prevSelector );

                    // next
                    if( direction === 'next' ) {
                        if(next.length) {
                            nextUrl = next.attr( 'href' );
                        }
                        else {
                            nextUrl = false;
                            lastSite = true;
                            console.log("last");
                        }
                    }
                    // prev
                    if( direction === 'prev' ) {
                        if(prev.length) {
                            prevUrl = prev.attr('href');
                        }
                        else {
                            prevUrl = false;
                            firstSite = true;
                        }
                    }
                    // recalculate element position
                    if( ! last_elem.hasClass( 'last' ) && opts.is_shop ) {
                        position_elem( last_elem, columns, elem );
                    }

                    if(direction === 'next' || typeof direction === 'undefined') {
                        last_elem.after(elem);
                    }
                    if(direction === 'prev') {
                        last_elem.before(elem);
                    }
                    // todo: window.history.pushState({data:  Math.random()}, $(data).find('title').text(), url);

                    $( '.yith-infs-loader' ).remove();


                    elem.addClass( 'yith-infs-animated' );

                    setTimeout( function(){
                        loading = false;
                        elem.removeClass( 'yith-infs-animated' );
                        $(document).trigger( 'yith_after_added_elem' );
                    }, 1000 );

                }
            });
        };


        // scroll event
        $( window ).on( 'scroll touchstart', function (){
            $(this).trigger('yith_infs_start');
        });

        $( window ).on( 'yith_infs_start', function(){
            var w       = $(this),
                offset  = $( opts.itemSelector ).last().offset();

            if ( ! loading && ! lastSite && w.scrollTop() >= Math.abs( offset.top - ( w.height() - 150 ) ) ) {
                // older
                main_ajax('next');
            }
            /*
            todo: load newer posts
            if(  ! loading && ! firstSite && w.scrollTop() <= Math.abs( offset.top - ( w.height() - 150  ))) {
                // newer
                // main_ajax('prev');
            }
            */
        });
    };

})( jQuery, window, document );