/**
 * Created by tmen on 30.03.16.
 */
(function ($) {
    /**
     * Options for the plugin
     */
    var settings;


    /**
     * all methods of the plugin
     * @type {{init: methods.init, start: methods.start, reset: methods.reset}}
     */
    var methods = {
        /**
         * Init the plugin
         * @param options
         * @returns {*}
         */
        init: function (options) {
            settings = $.extend({
                'offsetTop': 0,
                'offsetBottom': 0,
                'minScreenSize': 0
            }, options);
            return this.each(function () {
                /**
                 * Element
                 * @type {jQuery}
                 */
                var element = $(this);

                /**
                 * Top of the element
                 * @type {int}
                 */
                var top = element.offset().top + element.height() + settings.offsetTop;


                // register handler
                function searchBoxDown() {
                    if($(window).width() > settings.minScreenSize) {
                        var windowBottom = $(window).height() + $(window).scrollTop();
                        if (windowBottom > top) {
                            // bottom
                            element.css({
                                bottom: settings.offsetBottom,
                                position: 'fixed',
                                top: 'auto',
                                width: element.parent().width()
                            });
                        }
                        else {
                            // reset
                            element.css({bottom: 'auto', 'position': 'relative', top: '0', width: '100%'});
                        }
                    }
                }

                searchBoxDown();

                // scroll
                $(window).on("scroll", function () {
                    searchBoxDown();
                });

                // resize
                $(window).on("resize", function () {
                    searchBoxDown();
                });
            });
        }
    };

    /**
     * declare plugin with methods
     * @param methodOrOptions
     * @returns {*}
     */
    $.fn.affixedBottom = function(methodOrOptions)
    {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof methodOrOptions === 'object' || !methodOrOptions)
        {
            // Default to "init"
            return methods.init.apply(this, arguments);
        } else
        {
            $.error('Method ' + methodOrOptions + ' does not exist on this jquery plugin');
        }
    };
})(jQuery);