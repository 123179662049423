(function($)
{
    /**
     * Options for the plugin
     */
    var settings;

    /**
     * width of the origin eöement
     */
    var width;

    /**
     * position left
     */
    var left;


    /**
     * all methods of the plugin
     * @type {{init: methods.init, start: methods.start, reset: methods.reset}}
     */
    var methods = {
        /**
         * Init the plugin
         * @param options
         * @returns {*}
         */
        init: function(options)
        {
            settings = $.extend({
                'direction': 'left',
                'duration' : 150,
                'width': 300,
                'backgroundColor': '#fff'
            }, options);
            return this.each(function()
            {
                var that = this;
                $(this).data("settings", settings);

                width = $(that).find('img').outerWidth(true);
                $(this).find("li").each(function (){
                    // click event
                    $(this).find('a').on('click', function (e){
                        // if someone click the text load data
                        if($(that).hasClass('open') && !$(e.target).is('img')) {
                            methods.close(that);
                            return true;
                        }
                        else {
                            e.preventDefault();
                            methods.toggle(that);
                        }
                    });
                });
            });
        },
        /**
         * Open a menu
         */
        open : function (that) {
            settings = $(that).data("settings");
            console.log(that);
            // backdrop
            var backdrop = $('body');
            backdrop.shader({'onClick': function () {methods.toggle(that);}, 'duration': settings.duration});
            backdrop.shader('open');
            $(that).parent(".nav-affix").css({'z-index': '1010'});

            // set left
            left = -1 * settings.width;

            if (settings.direction === 'left') {
                //$(that).css({'background-color': settings.backgroundColor});
                $(that).animate({'left': left, 'width': settings.width + width}, {
                    duration: settings.duration, complete: function () {
                        $(that).addClass('open');
                    }
                });
            }
            else if (settings.direction === 'right') {
                // responsive menu
                // todo refactor
                if ($(window).width() <= 768) {
                    settings.width = $(window).width() - $(that).find('img').outerWidth(true);
                }
                // hack include search
                $(that).height($(window).height());
                $(that).find('.widget_search').css({'width': '100%'});

                $(that).animate({'width': settings.width + width}, {
                    duration: settings.duration, complete: function () {
                        $(that).addClass('open');
                    }
                });
            }
        },
        /**
         * Close the menu
         */
        close: function (that) {
            settings = $(that).data("settings");
            // backdrop
            var backdrop = $('body');
            backdrop.shader('close');
            $(that).parent(".nav-affix").css({'z-index': '999'});

            if(settings.direction === 'left') {
                $(that).removeClass('open');
                $(that).animate({'left': left + settings.width, width: width}, {
                    duration: settings.duration, complete: function () {
                        //$(that).css({'background-color': 'transparent', 'left':'auto'});
                        $(that).css({'left':'auto'});
                    }
                });
            }
            else if (settings.direction === 'right') {
                $(that).removeClass('open');
                $(that).animate({ width: width}, {
                    duration: settings.duration, complete: function () {
                        // $(that).css({'background-color': 'transparent'});
                    }
                });
            }
        },
        /**
         * Close or open filter depend on state
         */
        toggle: function (that) {
            if(typeof that === 'undefined'){
                that = this;
            }
            if ($(that).hasClass('open')) {
                methods.close(that);
            }
            else {
                methods.open(that);
            }
        },
        destroy: function (){
            $(this).find('a').off('click');
            $(this).removeData('settings');
            $(this).removeClass('open');
        }

};

    /**
     * declare plugin with methods
     * @param methodOrOptions
     * @returns {*}
     */
    $.fn.digikomFilter = function(methodOrOptions)
    {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof methodOrOptions === 'object' || !methodOrOptions)
        {
            // Default to "init"
            return methods.init.apply(this, arguments);
        } else
        {
            $.error('Method ' + methodOrOptions + ' does not exist on this jquery plugin');
        }
    };
})(jQuery);
